import React from "react";

import Layout from "../../components/Layout";
import EmployeeRoll from "../../components/EmployeeRoll";

export default class AboutIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <EmployeeRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
