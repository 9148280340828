import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class EmployeeRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: employees } = data.allMarkdownRemark;

    return (
      <div
        className="columns is-multiline is-centered"
        style={{ marginTop: "7rem" }}
      >
        {employees &&
          employees.map(({ node: employee }) => (
            <div
              key={`${employee.frontmatter.name}-employee-roll`}
              className="column is-4 has-text-centered"
            >
              <div className="section box has-background-primary">
                <div
                  style={{
                    width: "240px",
                    display: "inline-block",
                  }}
                >
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: employee.frontmatter.image,
                      alt: `Image of ${employee.frontmatter.name}`,
                    }}
                  />
                </div>
                <div className="has-text-white">
                  <p className="is-size-3 has-text-weight-bold">
                    {employee.frontmatter.name}
                  </p>
                  <p className="is-size-5 has-text-weight-bold is-bold-light">
                    {employee.frontmatter.position}
                  </p>
                  <p className="is-size-5">{employee.frontmatter.subtitle}</p>
                </div>
                <Link
                  className="button is-white mt-4 is-outlined has-text-weight-bold"
                  to={employee.fields.slug}
                >
                  View Profile
                </Link>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

EmployeeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query EmployeeRoll {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "about-person" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                position
                subtitle
                links {
                  name
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EmployeeRoll data={data} count={count} />}
  />
);
